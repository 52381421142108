import React, { useContext } from 'react';
import { Grid, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import CompanyLogo from './CompanyLogo';
import NavbarTabs from './NavbarTabs';
import SiteName from './SiteName';
import BetaStamp from './BetaStamp';
import { SignOut } from '../SignOut';
import { AccountInfoContext } from '../../App';

const MobilePadding = styled.div`
  padding-top: 2rem;
`;

export interface NavbarProps {
  onSignOutClick: () => void;
}

export const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
  const theme = useTheme();
  const isHiRes = useMediaQuery(theme.breakpoints.up('md'));
  const accountInfoContext = useContext(AccountInfoContext);

  function createHiResNavBar(): JSX.Element {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={3}>
          <Grid container direction="row" justify="center" alignItems="flex-start">
            <Grid item>
              <SiteName />
            </Grid>
            <Grid item>
              <BetaStamp />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <NavbarTabs />
        </Grid>
        <Grid item xs={2}>
          <CompanyLogo />
        </Grid>
        <Grid item xs={1}>
          <SignOut userName={accountInfoContext?.account?.name || 'Unknown User'} onSignOutClick={props.onSignOutClick} />
        </Grid>
      </Grid>
    );
  }

  function createLowResNavBar(): JSX.Element {
    return (
      <MobilePadding>
        <Grid container direction="column" spacing={2} justify="center" alignItems="center">
          <Grid item>
            <Grid container direction="row" spacing={4} justify="space-between" alignItems="center">
              <Grid item>
                <Grid container direction="row" justify="flex-start">
                  <Grid item>
                    <SiteName />
                  </Grid>
                  <Grid item>
                    <BetaStamp />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <CompanyLogo />
                <SignOut userName={accountInfoContext?.account?.name || 'Unknown User'} onSignOutClick={props.onSignOutClick} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <NavbarTabs />
          </Grid>
        </Grid>
      </MobilePadding>
    );
  }

  return isHiRes ? createHiResNavBar() : createLowResNavBar();
};
