import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import { Navbar } from './components/nav/Navbar';
import { About } from './pages/About';
import { Match } from './pages/Match';
import { Preferences } from './pages/Preferences';
import { getMatch } from './services/MatchService';
import MainTheme from './MainTheme';
import './App.css';
import { AuthenticationState, AzureAD, IAccountInfo, IAzureADFunctionProps } from 'react-aad-msal';
import { authProvider } from './services/AuthService';

export const RoutesAvailable = {
  About: 'About',
  Match: 'Match',
  Preference: 'Preference',
  Admin: 'Admin',
};

export const AccountInfoContext = React.createContext<IAccountInfo | undefined>(undefined);

const App = () => {
  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState, login, logout, accountInfo, error }: IAzureADFunctionProps) => {
        if (error) {
          console.error(error);
          return <div>Authentication error: {JSON.stringify(error)}</div>;
        }

        if (authenticationState === AuthenticationState.Unauthenticated) {
          login();
        }

        if (authenticationState !== AuthenticationState.Authenticated) {
          return null;
        }

        if (!accountInfo || error) {
          return <div>Authentication error: {JSON.stringify(error)}</div>;
        }

        return (
          <Router>
            <ThemeProvider theme={MainTheme}>
              <AccountInfoContext.Provider value={accountInfo}>
                <Box className="App">
                  <Navbar onSignOutClick={logout} />
                  <Divider />
                  <Switch>
                    <Route path={'/' + RoutesAvailable.About}>
                      <About />
                    </Route>
                    <Route path={'/' + RoutesAvailable.Match}>
                      <Match onRevealMatchClick={getMatch} />
                    </Route>
                    <Route path={'/' + RoutesAvailable.Preference}>
                      <Preferences />
                    </Route>
                    <Route path={'/' + RoutesAvailable.Admin}>
                      <p>{RoutesAvailable.Admin}</p>
                    </Route>
                    <Route exact path="/">
                      <Redirect to={'/' + RoutesAvailable.About} />
                    </Route>
                  </Switch>
                </Box>
              </AccountInfoContext.Provider>
            </ThemeProvider>
          </Router>
        );
      }}
    </AzureAD>
  );
};

export default App;
