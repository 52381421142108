import React from 'react';
import { ButtonThemed } from './ButtonThemed';
import { Box, Typography } from '@material-ui/core';

export interface SignOutProps {
  onSignOutClick: () => void;
  userName: string;
}

export const SignOut: React.FunctionComponent<SignOutProps> = (props) => {
  return (
    <Box>
      <Typography variant="body1">{props.userName}</Typography>
      <ButtonThemed onClick={props.onSignOutClick}>Sign Out</ButtonThemed>
    </Box>
  );
};
