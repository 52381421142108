import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar } from '@material-ui/core';
import { UserDetails } from '../types';
import styled from 'styled-components';

const AvatarStyled = styled(({ ...rest }) => <Avatar {...rest} />)`
  width: 7.5rem;
  height: 7.5rem;
`;

export type ProfileProps = Pick<UserDetails, 'capability' | 'name' | 'role' | 'profileImageURI'> & {
  onImageLoadCompleted: (loadStatus: ImageStatus) => void;
};

export type ImageStatus = '' | 'loaded' | 'load failed' | 'no image';

export const Profile: React.FunctionComponent<ProfileProps> = (props) => {
  const [isProfileHidden, setIsProfileHidden] = useState(!!props.profileImageURI);

  useEffect(() => {
    if (!isProfileHidden) {
      props.onImageLoadCompleted('no image');
    }
  }, [isProfileHidden, props]);

  const handleImageLoaded = () => {
    setIsProfileHidden(false);
    props.onImageLoadCompleted('loaded');
  };

  const handleImageError = () => {
    setIsProfileHidden(false);
    props.onImageLoadCompleted('load failed');
  };

  return (
    <Box hidden={isProfileHidden}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box width="7.5rem" height="7.5rem">
          <AvatarStyled
            alt={props.name}
            src={props.profileImageURI}
            onLoad={handleImageLoaded}
            onError={handleImageError}
          />
        </Box>
        <Typography variant="h6">{props.name}</Typography>
        <Typography variant="subtitle2">{props.role}</Typography>
        <Typography variant="subtitle2">{props.capability}</Typography>
      </Box>
    </Box>
  );
};
