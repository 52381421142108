import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';

export type ButtonThemedProps = Omit<ButtonProps, 'variant' | 'color'>;

export const ButtonThemed: React.FunctionComponent<ButtonThemedProps> = ({ children, ...props }) => (
  <Button {...props} variant="contained" color="primary">
    {children}
  </Button>
);
