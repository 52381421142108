import React, { useState } from 'react';
import { CircularProgress, FormLabel, Grid, Typography } from '@material-ui/core';
import { Profile } from '../components/Profile';
import ConfettiAnimation from '../components/ConfettiAnimation';
import styled from 'styled-components';
import { PageThemed } from '../components/PageThemed';
import { toast } from 'react-toastify';
import { UserDetails } from '../types';
import { ButtonThemed } from '../components/ButtonThemed';

export type MatchProps = {
  onRevealMatchClick: () => Promise<UserDetails>;
};

const AnimationGrid = styled(Grid)`
  position: absolute;
  top: 5rem;
  left: 0;
`;

export const Match: React.FC<MatchProps> = (props) => {
  const [isMatchRequested, setIsMatchRequested] = useState(false);
  const [hasProfileImageLoadCompleted, setHasProfileImageLoadCompleted] = useState(false);
  const [matchedUser, setMatchedUser] = useState({} as UserDetails);
  const [isPersonRevealed, setIsPersonRevealed] = useState(false);
  const [infoText, setInfoText] = useState('Select the button below to find out.');
  const [matchHeaderText, setMatchHeaderText] = useState('Who will you be matched with?');

  const onRevealMatchButtonClick = async () => {
    setIsMatchRequested(true);
    try {
      const matchedUser = await props.onRevealMatchClick();
      setMatchedUser(matchedUser);
      setIsPersonRevealed(true);
    } catch (error) {
      console.error(error);
      toast.error('Oops - Failed to get a match', {
        autoClose: false,
      });
      setTimeout(() => setIsMatchRequested(false), 1000);
    }
  };

  const profileImageLoadDone = () => {
    setMatchHeaderText('Your new match');
    setInfoText('Well, this is exciting!');
    setHasProfileImageLoadCompleted(true);
  };

  return (
    <PageThemed>
      <Grid item>
        <Typography variant="h6">{matchHeaderText}</Typography>
        <FormLabel>
          <Typography variant="caption">{infoText}</Typography>
        </FormLabel>
      </Grid>
      <Grid item>
        {!isMatchRequested && <ButtonThemed onClick={onRevealMatchButtonClick}>Reveal my match</ButtonThemed>}
        {isMatchRequested && (!isPersonRevealed || !hasProfileImageLoadCompleted) && <CircularProgress />}
        {isMatchRequested && isPersonRevealed && (
          <Profile onImageLoadCompleted={profileImageLoadDone} {...matchedUser} />
        )}
      </Grid>
      {hasProfileImageLoadCompleted && (
        <AnimationGrid item>
          <ConfettiAnimation height="100%" width="100%" />
        </AnimationGrid>
      )}
    </PageThemed>
  );
};
