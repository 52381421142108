import { Typography } from '@material-ui/core';
import styled from 'styled-components';

const TabLabel = styled(Typography)`
  text-transform: none;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1.5rem 0;
`;

export default TabLabel;
