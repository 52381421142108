import React, { useState } from 'react';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { PageThemed } from '../components/PageThemed';
import { ButtonThemed } from '../components/ButtonThemed';
import { loadPreferences, savePreferences } from '../services/PreferencesService';
import { UserPreferences } from '../types';
import { toast } from 'react-toastify';

const Section = styled(Grid)`
  text-align: left;
`;

export const Preferences: React.FC = () => {
  const [isLoadingPreferences, setLoadingPreferences] = useState(false);
  const [preferences, setPreferences] = useState<UserPreferences | undefined>(undefined);

  if (!preferences && !isLoadingPreferences) {
    setLoadingPreferences(true);
    loadPreferences().then((loadedPreferences) => {
      setPreferences(loadedPreferences);
      setLoadingPreferences(false);
    });
  }

  const updateOptIn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreferences({
      ...preferences,
      isOptIn: event.target.value === 'true',
    });
  };

  const persistPreferences = async () => {
    const result = await savePreferences(preferences as UserPreferences);
    if (result) {
      toast.success('Preferences updated successfully', {
        autoClose: 5000,
      });
    } else {
      toast.error('Failed to update preferences', {
        autoClose: false,
      });
    }
  };

  return (
    <PageThemed>
      {isLoadingPreferences && (
        <Grid item alignItems="center">
          <CircularProgress />
        </Grid>
      )}
      {!isLoadingPreferences && (
        <>
          <Section item>
            <Typography variant="h6">Participation</Typography>
            <FormLabel>
              <Typography variant="caption">Encouraged, but voluntary</Typography>
            </FormLabel>
            <RadioGroup
              aria-label="Participate"
              name="OptInOrOut"
              value={String(preferences?.isOptIn)}
              onChange={updateOptIn}
            >
              <FormControlLabel
                value="true"
                control={<Radio color="primary" />}
                label={<Typography variant="body2">Opt In: I want to participate</Typography>}
              />
              <FormControlLabel
                value="false"
                control={<Radio color="primary" />}
                label={<Typography variant="body2">Opt Out: I do not want to participate</Typography>}
              />
            </RadioGroup>
          </Section>
          <Box>
            <ButtonThemed onClick={persistPreferences}>Update</ButtonThemed>
          </Box>
        </>
      )}
    </PageThemed>
  );
};
