const screenWidths = {
  xs: `0px`,
  sm: `600px`,
  md: `960px`,
  lg: `1280px`,
  xl: `1920px`,
};

const Devices = {
  mobile: `(min-width: ${screenWidths.xs})`,
  tablet: `(min-width: ${screenWidths.md})`,
  desktop: `(min-width: ${screenWidths.lg})`,
};

export default Devices;
