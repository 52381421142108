import styled from 'styled-components';
import React from 'react';

const SquadLogoStyle = styled.img`
  width: 14rem;
`;

const SquadLogo = () => {
  return <SquadLogoStyle src="pictures/QuokkaSquadLogo.png" alt="Quokka Squad Logo" />;
};

export default SquadLogo;
