import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let MainTheme = createMuiTheme({
  typography: {
    h1: {
      fontSize: 32,
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
    button: {
      fontSize: 12,
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#0000ff',
    },
  },
});
MainTheme = responsiveFontSizes(MainTheme);

export default MainTheme;
