import styled from 'styled-components';
import React from 'react';
import Devices from '../../Devices';

const CompanyLogoStyle = styled.img`
  @media ${Devices.mobile} {
    width: 4rem;
  }
  @media ${Devices.tablet} {
    width: 5rem;
  }
  @media ${Devices.desktop} {
    width: 5rem;
  }
`;

const CompanyLogo = () => {
  return <CompanyLogoStyle src="pictures/tp_logo.png" alt="Telstra Purple logo" />;
};

export default CompanyLogo;
