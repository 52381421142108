import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import React from 'react';
import TabLabel from './TabLabel';
import { RoutesAvailable } from '../../App';
import { Tabs, Tab } from '@material-ui/core';
import styled from 'styled-components';

const ResponsiveTab = styled(Tab)`
  min-width: 100px;
  max-width: 160px;
`;

const NavbarTabs = () => {
  const [selectedTab, setSelectedTab] = useState(RoutesAvailable.About);
  const browserHistory = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    setSelectedTab(pathname.replace('/', ''));
  }, [location]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
    browserHistory.push(`/${newValue}`);
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      centered
      variant="fullWidth"
    >
      <ResponsiveTab label={<TabLabel>{RoutesAvailable.About}</TabLabel>} value={RoutesAvailable.About} />
      <ResponsiveTab label={<TabLabel>{RoutesAvailable.Match}</TabLabel>} value={RoutesAvailable.Match} />
      <ResponsiveTab label={<TabLabel>{RoutesAvailable.Preference}</TabLabel>} value={RoutesAvailable.Preference} />
      {/* ToDo after MVP -  <ResponsiveTab label={<TabLabel>{RoutesAvailable.Admin}</TabLabel>} value={RoutesAvailable.Admin} /> */}
    </Tabs>
  );
};

export default NavbarTabs;
