import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { RoutesAvailable } from '../App';
import SquadLogo from '../components/SquadLogo';
import styled from 'styled-components';
import { ButtonThemed } from '../components/ButtonThemed';

const MatchMeatRepeatStyle = styled(Box)`
  text-align: start;
`;

const AboutGridStyled = styled(Grid)`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const AboutHeaderBoxStyled = styled(Box)`
  padding-top: 6rem;
`;

const AboutHeaderTypographyStyled = styled(Typography)`
  text-align: left;
`;

const AboutInfoTextBoxStyled = styled(Box)`
  padding-top: 2rem;
`;

const AboutInfoTextTypographyStyled = styled(Typography)`
  text-align: left;
  margin-bottom: 1.5rem;
`;

const MobileBoxStyled = styled(Box)`
  padding-top: 3rem;
`;

const MobileGridStyled = styled(Grid)`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const DesktopBoxStyled = styled(Box)`
  padding-top: 4rem;
`;

const DesktopGridStyled = styled(Grid)`
  flex-direction: row;
  justify-content: space-between;
`;

const DesktopTakeActionGridContainerStyled = styled(Grid)`
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  align-content: stretch;
`;
const DesktopTakeActionGridStyled = styled(Grid)`
  flex-direction: column;
  align-items: flex-start;
`;

const DesktopBoxSpacer = styled(Box)`
  padding-top: 2rem;
`;

const MatchMeatRepeat = () => {
  return (
    <MatchMeatRepeatStyle>
      <Typography variant="subtitle1">Match. Meet. Repeat.</Typography>
    </MatchMeatRepeatStyle>
  );
};

const FixedWidthContainer = styled(Container)`
  max-width: 550px;
`;

export const About: React.FunctionComponent = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const browserHistory = useHistory();
  const selectMatchesTab = () => {
    browserHistory.push(`/${RoutesAvailable.Match}`);
  };

  function layoutForDesktop(): JSX.Element {
    return (
      <DesktopBoxStyled>
        <DesktopGridStyled container>
          <Grid item>
            <DesktopTakeActionGridContainerStyled container>
              <Grid item>
                <DesktopTakeActionGridStyled container>
                  <Grid item>
                    <MatchMeatRepeat />
                  </Grid>
                  <Grid item>
                    <DesktopBoxSpacer />
                    <ButtonThemed onClick={selectMatchesTab}>Let&apos;s get on with it</ButtonThemed>
                  </Grid>
                </DesktopTakeActionGridStyled>
              </Grid>
            </DesktopTakeActionGridContainerStyled>
          </Grid>
          <Grid item>
            <SquadLogo />
          </Grid>
        </DesktopGridStyled>
      </DesktopBoxStyled>
    );
  }

  function layoutForMobile(): JSX.Element {
    return (
      <MobileBoxStyled>
        <MobileGridStyled container spacing={4}>
          <Grid item>
            <MatchMeatRepeat />
          </Grid>
          <Grid item>
            <SquadLogo />
          </Grid>
          <Grid item>
            <ButtonThemed onClick={selectMatchesTab}>Let&apos;s get on with it</ButtonThemed>
          </Grid>
        </MobileGridStyled>
      </MobileBoxStyled>
    );
  }

  return (
    <FixedWidthContainer>
      <AboutGridStyled container>
        <Grid item>
          <AboutHeaderBoxStyled>
            <AboutHeaderTypographyStyled variant="h1">Connecting our awesome peeps</AboutHeaderTypographyStyled>
          </AboutHeaderBoxStyled>
        </Grid>

        <Grid item>
          <AboutInfoTextBoxStyled>
            <AboutInfoTextTypographyStyled variant="body1">
              Meetup Roulette was created as part of the Quokka Squad culture working group. We saw a need to foster
              more meaningful 1:1 connections based on YOUR feedback.
            </AboutInfoTextTypographyStyled>
            <AboutInfoTextTypographyStyled variant="body1">
              How does it work? You match with someone; they get notified via email; you coordinate a time that suits
              you both to connect over a hot beverage of choice and expense it back to T Purple (virtually during COVID
              restrictions of course)...simple.
            </AboutInfoTextTypographyStyled>
            <AboutInfoTextTypographyStyled variant="body1">
              When claiming your expense, please select the category: Reward &amp; Recognition. There isn&apos;t an
              endless money pit however, we believe you have good judgement and we trust you to use it.
            </AboutInfoTextTypographyStyled>
          </AboutInfoTextBoxStyled>
        </Grid>
      </AboutGridStyled>

      {isDesktop ? layoutForDesktop() : layoutForMobile()}
    </FixedWidthContainer>
  );
};
