import React from 'react';
import Lottie, { LottieProps, Options } from 'react-lottie';
import * as animationData from '../assets/lottie/exploding-ribbon-and-confetti.json';
import { Box } from '@material-ui/core';

export type ConfettiAnimationProps = Pick<LottieProps, 'width' | 'height'>;

export interface LottieControlState {
  speed: number;
}

export default class ConfettiAnimation extends React.Component<ConfettiAnimationProps, LottieControlState> {
  constructor(props: ConfettiAnimationProps) {
    super(props);
    this.state = { speed: 1 };
  }

  componentDidMount() {
    this.setState({ speed: 0.3 });
  }

  render() {
    const defaultOptions: Options = {
      loop: false,
      autoplay: true,
      animationData: (animationData as any).default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    return (
      <Box>
        <Lottie
          options={defaultOptions}
          height={this.props.height}
          width={this.props.width}
          speed={this.state.speed}
          isClickToPauseDisabled={true}
        />
      </Box>
    );
  }
}
