import { UserPreferences } from '../types';
import { getAuthHeader } from './AuthService';
import axios from 'axios';

let cachedPreferences: UserPreferences;

// TODO Load from API (and other user details?)
export const loadPreferences = async (): Promise<UserPreferences> => {
  const headers = await getAuthHeader();

  if (!cachedPreferences) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_FUNCTION_HOSTNAME}/api/User/Me`, { headers });
      cachedPreferences = { isOptIn: response.data.optedIn };
    } catch (error) {
      // TODO FIXME - this should at least be a `toast.error()`
      console.log(error);
    }
  }
  return cachedPreferences;
};

export const savePreferences = async (userPreferences: UserPreferences): Promise<boolean> => {
  const headers = await getAuthHeader();
  try {
    await axios.put(
      `${process.env.REACT_APP_FUNCTION_HOSTNAME}/api/User`,
      { optedIn: userPreferences.isOptIn },
      { headers }
    );
    cachedPreferences = userPreferences;
    return true;
  } catch (error) {
    return false;
  }
};
