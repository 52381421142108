import styled from 'styled-components';
import React from 'react';
import { Grid, Paper } from '@material-ui/core';

const GridMainContainerStyled = styled(Grid)`
  margin-top: 3rem;
`;

const PaperStyled = styled(({ ...rest }) => <Paper elevation={3} {...rest} />)`
  width: 25rem;
  height: 30rem;
  max-width: 100%;
  max-height: 100%;
  padding: 2rem;
  position: relative;
`;

export const PageThemed: React.FC<{}> = (props) => {
  return (
    <GridMainContainerStyled container direction="column" justify="center" alignItems="center">
      <PaperStyled>
        <Grid container direction="column" justify="space-between" spacing={5}>
          {props.children}
        </Grid>
      </PaperStyled>
    </GridMainContainerStyled>
  );
};
