import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import React from 'react';

const BetaStampStyle = styled(Typography)`
  color: #2c7377;
  font-weight: 500;
  font-size: 0.8rem;
`;

const BetaStamp = () => {
  return (
    <BetaStampStyle variant="body1" color="primary">
      BETA
    </BetaStampStyle>
  );
};

export default BetaStamp;
