import { LoginType, MsalAuthProvider } from 'react-aad-msal';

export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY_URL || 'https://login.microsoftonline.com/common',
      clientId: process.env.REACT_APP_AAD_APP_CLIENT_ID || '',
      postLogoutRedirectUri: window.location.origin,
      redirectUri: window.location.origin,
      validateAuthority: true,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: true,
    },
  },
  {
    // The addition scopes are required for the Azure Function to act on behalf of the
    // authenticated user. We are using the short-form scopes that relate to the MS Graph API Permissions
    extraScopesToConsent: ['User.Read', 'Mail.Send'],
    scopes: [
      `api://${process.env.REACT_APP_AAD_APP_CLIENT_ID}/access_as_user`,
      `api://${process.env.REACT_APP_AAD_APP_CLIENT_ID}/PurpleGraph.Access`
    ],
  },
  {
    loginType: LoginType.Redirect,
  }
);

export const getAuthHeader = async () => {
  const accessToken = (await authProvider.getAccessToken()).accessToken;
  const headerObject = {
    Authorization: `Bearer ${accessToken}`,
  };
  return headerObject;
};
