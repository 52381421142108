import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import React from 'react';

const SiteNameStyle = styled(Typography)`
  font-size: 1.4rem;
  font-weight: 300;
`;

const SiteName = () => {
  return (
    <SiteNameStyle variant="body1" color="primary">
      Meetup Roulette
    </SiteNameStyle>
  );
};

export default SiteName;
